<template>
    <div>
        <LiefengContent>
            <template v-slot:title >
                <Button v-for="(item,index) in sheetList" :key="item" :type="active == index? 'primary':''" @click="getListData(item.sheetId,index)">{{item.name}}</Button>
                <Button style="display:none"></Button>
            </template>
            <template v-slot:toolsbarRight>
                <Button type="primary" style="margin-right:10px" @click="matching">匹配转换当前表单</Button> 
                <Button type="primary" style="margin-right:10px" @click="changeStatus">标记已全部完成匹配</Button> 
            </template>
            <template v-slot:contentArea>
                <LiefengTable
                    class="firsttable" 
                    :talbeColumns="table.talbeColumns"
                    :tableData="table.tableData"
                    :loading="table.loading"
                    :curPage="table.page"
                    :total="table.total"
                    :pagesizeOpts="[100, 200, 500]"
                    :page-size="table.pageSize"
                    @hadlePageSize="hadlePageSize"
                    :fixTable="true"
                
                ></LiefengTable>
                <!-- <Table class="firsttable" border :columns="columnsList" :data="dataList"> </Table> -->

                <LiefengModal
                    title="转换设置"
                    :value="switchStatus"
                    @input="changeSwitchStatus"
                    width="600px"
                    height="600px"

                >
                    <template v-slot:contentarea>
                        <Table height="528"  :columns="columns1" :data="data1"></Table>
                    </template>
                    <template v-slot:toolsbar>
                        <Button type="info" @click="changeSwitchStatus(false)" style="margin-right: 10px" >取消</Button >
                        <Button type="primary" :loading="btnLoading" @click="enterSave">确定转换</Button>
                    </template>
                </LiefengModal>
            </template>
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
export default {
    components: { LiefengContent, LiefengTable,LiefengModal },
    data() {
        return {
            // columnsList:[],
            // dataList:[],

            table: {
                talbeColumns: [],
                tableData: [],
                loading: false,
                page: 1,
                total: 0,
                pageSize: 100,
                currentPage: 1,
            },

            columns1:[
                {
                    title: '序号',
                    key: 'no',
                    align: "center",
                    width:80
                },
                {
                    title: '字段',
                    key: 'name',
                    align: "center",
                },
                {
                    title: '对应匹配转换字段',
                    key: 'num',
                    align: "center",
                    render: (h, params) => {
                            return h('Select', {
                                props:{
                                    value: params.row.num,//绑定数据
									transfer: true,//不受表格高度影响
                                    clearable:true
                                },
                                on: {
                                        'on-change':(event) => {
                                            let value = event
                                            // if (event) value = event
                                            // else value = params.row.num
                                            this.data1.map(item => {
                                                if (item.no == params.row.no) {
                                                    item.num = value
                                                }
                                            })
                                            // params.row.num = event                                      
                                        } 
                                },
                            },
                            [
                                this.optionList.map((val)=>{ //dataList就是要展示的数组
                                        return h('Option', {
                                    props: {value: val.dictKey}  
                                },val.dictValue);  
                                })
                          ]);
                        },
                },
            ],
            data1:[
                
            ],
            timer: null,

            switchStatus:false,

            optionList:[],
            btnLoading:false,
            sheetId:'',

            sheetList:[],
            active:0
        }
    },

    async created() {
        await this.getSheetList()
        if(this.sheetList.length != 0)
        await this.getList()
        this.getDictTyet('MATCH_CONVERSION')
    },
    methods: {
        getDictTyet(dictType){
            this.$get("/dictlist/lf-common/api/lfcommon/pc/dict/selectDictDataList", {
                dictType,
                appCode:""
            }).then(res => {
                if(res.code == 200) {
                    this.optionList = res.dataList
                }
            })
        },

        // 切换分页事件
        hadlePageSize(val) {
            this.table.page = val.page
            this.table.pageSize = val.pageSize
            this.getList()
        },
        // listSheetImp
        async getSheetList(){
            await this.$get("/syaa/pc/sy/user/gridManage/listSheetImp", {
                archivesId: this.$route.query.archivesId,
            }).then( res => {
                if(res.code == 200){
                    if(res.dataList.length != 0){
                        this.sheetList = res.dataList
                        this.active = 0
                        this.sheetId = this.sheetList[0].sheetId
                    }else{
                        this.$Message.warning({
                            content: "暂无数据",
                            background: true,
                        })
                    }
                }else{
                    this.$Message.error({
                        content: "获取数据失败",
                        background: true,
                    })
                }
            })
        },
        getListData(sheetId,index){
            this.active = index
            this.sheetId = sheetId
            this.getList()
        },
        // 接口部分
        async getList(sheetId) {
            this.table.loading = true
            try {
                await this.$get("/syaa/pc/sy/user/gridManage/listSheetRecordByPage", {
                    // archivesId: this.$route.query.archivesId,
                    page: this.table.page,
                    pageSize: this.table.pageSize,
                    sheetId:this.sheetId,
                }).then(res => {
                    this.table.loading = false
                    if (res && res.code == 200 && res.dataList.length) {
                        this.table.talbeColumns = []
                        this.table.tableData = []

                        let num = 20
                        for(let i=0;i<num;i++){
                            this.table.talbeColumns.push({
                                title: i.toString(),
                                key: i.toString(),
                                align: "center",
                                width:200
                            })
                        }
                        this.table.talbeColumns.push({
                            title: "操作",
                            align: "center",
                            fixed:'right',
                            width: 120,
                            render: (h, params) => {
                                return h("div", [
                                    h(
                                        "Button",
                                        {
                                            props: {
                                                type: "error",
                                                size: "small",
                                            },
                                            nativeOn: {
                                                click: () => {
                                                    this.delete(params.row)
                                                },
                                            },
                                        },
                                        "删除整行"
                                    ),
                                ])
                            },
                        })


                        // console.log(this.table.talbeColumns)
                        // this.sheetId = res.dataList[1].id?res.dataList[1].id:''
                        this.table.tableData = res.dataList.map( (item,index) => {
                            console.log(index)
                            return {
                                ...JSON.parse(item.dataJson),
                                id:item.id       
                            }
                           
                        })
                        this.table.total = res.maxCount
                        this.table.currentPage = res.currentPage
                        
                    } else {
                        this.$Message.error({
                            content: "获取数据失败",
                            background: true,
                        })
                        return
                    }
                })
            } catch (e) {
                console.log(e)
                this.table.loading = false
                this.$Message.error({
                    content: "获取数据失败",
                    background: true,
                })
                return
            }
        },

        // 匹配转化
        matching(){
            if(this.table.tableData.length == 0){
                this.$Message.warning({
                    content: "暂无数据",
                    background: true,
                })
                return
            }
            this.switchStatus = true
            this.data1 = []
            delete this.table.tableData[0].id
            for(let key in this.table.tableData[0]){
                this.data1.push({
                    no:key,
                    name:this.table.tableData[0][key],
                    num:''
                })
            }
        },

        changeStatus(){
            this.$post("/syaa/pc/sy/user/gridManage/completeMatch", {
                archivesId:this.$route.query.archivesId
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: '标记成功',
                        background: true,
                    })
                } else {
                    this.$Message.error({
                        content: '标记失败',
                        background: true,
                    })
                }
            })
        },

        //转换框
        changeSwitchStatus(val){
            this.switchStatus = val
        },

        // 确定转换
        enterSave(){
            this.btnLoading = true
            console.log(this.data1)
            let ruleMap = {}
            this.data1.map( item => {
                ruleMap[item.no] = item.num
            })
            console.log(ruleMap)

            // return 
            
            this.$post("/syaa/pc/sy/user/gridManage/sheetMatchConversion", {
                sheetId:this.sheetId,
                ruleMap:ruleMap,
                orgCode:this.$route.query.orgCode
            },{"Content-Type":"application/json"}).then(res => {
                if (res.code == 200) {
                    this.btnLoading = false
                    this.switchStatus = false
                    this.$Message.success({
                        content: '转换成功',
                        background: true,
                    })
                    this.getList()
                } else {
                    this.$Message.error({
                        content: '转换失败',
                        background: true,
                    })
                }
            })

        },
        delete(data){
            this.$Modal.confirm({
                title: "提醒",
                content: `是否删除当前行整行`,
                okText: "确定",
                cancelText: "取消",
                loading: true,
                onOk: () => {
                    this.$post("/syaa/pc/sy/user/gridManage/deleteSheetRecordById", {
                        recordId:data.id
                    }).then(res => {
                        this.$Modal.remove()
                        if (res.code == 200) {
                            this.$Message.success({
                                content: '成功',
                                background: true,
                            })
                            this.getList()
                        } else {
                            this.$Message.error({
                                content: res.desc,
                                background: true,
                            })
                        }
                    })
                },
            })
        },
    },
}
</script>

<style lang="less" scoped>
/deep/.liefeng-icon-dynamic-filling{
    display: none;
}
.export-modal {
    /deep/ #modal_contentarea {
        overflow: visible !important;
        height: 270px;
    }
}
.firsttable{
    /deep/.ivu-table-header{
        display: none;
    }
    /deep/.ivu-table-fixed-header{
        display: none;
    }
}
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
/deep/#toolsbarLeft {
    overflow-x: auto;
    & > div{
        margin-top: 0 !important;
    }
}
/deep/#toolsbarRight{
    width: 340px;
    overflow: inherit;
}
</style>